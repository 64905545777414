import { ClipBoard } from 'components/common/ClipBoard/ClipBoard';
import { CurrencyIcon } from 'components/common/CurrencyIcon/CurrencyIcon';
import { getAccountNumber, getAccountTitle } from 'components/utils';
import { CRYPTO_CURRENCY_PRECISION, CURRENCIES } from 'services/currenciesConstant';
import { amountFormattedValue, formatAccountNumber } from 'services/utils';
import { ActionButton } from 'uikit/ActionButton/ActionButton';
import './Card.scss';

export const Card = ({
  type,
  isCardAccount,
  isManualAccount,
  isCryptoAccount,
  account,
  balance,
  currency,
  reserved,
  onExclaimPointClick,
  onTransactionClick,
  onPaymentClick,
  mobileText,
  tooltipText = []
}) => {
  const accountTitle = getAccountTitle(account);
  const accountNumber = getAccountNumber(account);

  if (type === 'list') {
    return (
      <div className={'account-card-row list'}>
        <div className={'account-card-item'}>
          <p className={'card-mobile-text'}>{mobileText?.account}</p>
          <p className={'account'}>{account}</p>
        </div>
        <div className={'account-card-item'}>
          <p className={'card-mobile-text'}>{mobileText?.currency}</p>
          <p className={'currency'}>{currency}</p>
        </div>
        <div className={'account-card-item'}>
          <p className={'card-mobile-text'}>{mobileText?.reserved}</p>
          <p className={'reserved'}>
            {amountFormattedValue(reserved, isCryptoAccount ? CRYPTO_CURRENCY_PRECISION : 2) + ' ' + currency}
          </p>
        </div>
        <div className={'account-card-item'}>
          <p className={'card-mobile-text'}>{mobileText?.balance}</p>
          <p className={'balance'}>
            {amountFormattedValue(balance, isCryptoAccount ? CRYPTO_CURRENCY_PRECISION : 2) + ' ' + currency}
          </p>
        </div>
        <div className={'account-card-item'}>
          <p className={'card-mobile-text'}>{mobileText?.actions}</p>
          <div className={'wrapper-icon'}>
            <ActionButton tooltipText={tooltipText && tooltipText[2]} onClick={onExclaimPointClick} />
            <ActionButton
              tooltipText={tooltipText && tooltipText[0]}
              iconType={'transaction'}
              onClick={onTransactionClick}
            />
            <ActionButton
              tooltipText={tooltipText && tooltipText[1]}
              iconType={'new-payment'}
              onClick={onPaymentClick}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'account-card'}>
      <div className={'left-side'}>
        <div className={'currency-wrapper'}>
          <CurrencyIcon
            isCardAccount={isCardAccount}
            currencyIcon={CURRENCIES[currency]?.icon ? CURRENCIES[currency].icon : null}
          />
          <p className={'currency-code'}>{CURRENCIES[currency].code}</p>
        </div>
        <div className={'wrapper-balance'}>
          {!isCryptoAccount && <p className={'currency'}>{CURRENCIES[currency].symbol}</p>}
          <p className={'balance'}>{amountFormattedValue(balance, isCryptoAccount ? CRYPTO_CURRENCY_PRECISION : 2)}</p>
        </div>
        <div className={'account-wrapper'}>
          <ClipBoard
            isCardComponent={true}
            copyText={isCardAccount || isManualAccount ? accountNumber : accountTitle}
          />
          <p className={'account-number'}>
            {isCardAccount || isManualAccount ? accountTitle : formatAccountNumber(accountTitle)}
          </p>
        </div>
      </div>
      <div className={'buttons-wrapper'}>
        <ActionButton
          isCardIcon={true}
          tooltipText={tooltipText[1]}
          iconType={'new-payment'}
          onClick={onPaymentClick}
        />
        <ActionButton isCardIcon={true} tooltipText={tooltipText[2]} iconType={'info'} onClick={onExclaimPointClick} />
        <ActionButton
          isCardIcon={true}
          tooltipText={tooltipText[0]}
          iconType={'transaction'}
          onClick={onTransactionClick}
        />
      </div>
    </div>
  );
};

Card.propTypes = {};
