import Turnstile from 'react-turnstile';
import PropTypes from 'prop-types';

const TurnstileCaptcha = ({ siteKey, handleTokenVerify }) => {
  return <Turnstile theme={'light'} sitekey={siteKey} onVerify={handleTokenVerify} refreshExpired={'auto'} />;
};

TurnstileCaptcha.propTypes = {
  siteKey: PropTypes.string,
  handleTokenVerify: PropTypes.func
};

export default TurnstileCaptcha;
