import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from 'assets/iconButtons/edit.svg';
import { ReactComponent as InfoIcon } from 'assets/iconButtons/details.svg';
import { ReactComponent as InfoCardIcon } from 'assets/iconButtons/details-with-background-card-icon.svg';
import { ReactComponent as NewPaymentIcon } from 'assets/iconButtons/new-payment-with-background.svg';
import { ReactComponent as NewPaymentCardIcon } from 'assets/iconButtons/new-payment-with-background-card-icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/iconButtons/remove.svg';
import { ReactComponent as TransactionIcon } from 'assets/iconButtons/transactions-with-background.svg';
import { ReactComponent as TransactionCardIcon } from 'assets/iconButtons/transactions-with-background-card-icon.svg';
import './ExclamationPoint.scss';

export const ActionButton = ({ isCardIcon = false, onClick, iconType, tooltipText }) => {
  const getIcon = (iconType) => {
    if (isCardIcon && iconType === 'new-payment') return <NewPaymentCardIcon className={'exclamation-point-icon'} />;
    if (!isCardIcon && iconType === 'new-payment') return <NewPaymentIcon className={'exclamation-point-icon'} />;
    if (iconType === 'edit') return <EditIcon className={'exclamation-point-icon'} />;
    if (iconType === 'remove') return <RemoveIcon className={'exclamation-point-icon'} />;
    if (isCardIcon && iconType === 'transaction') return <TransactionCardIcon className={'exclamation-point-icon'} />;
    if (!isCardIcon && iconType === 'transaction') return <TransactionIcon className={'exclamation-point-icon'} />;
    if (isCardIcon && iconType === 'info') return <InfoCardIcon className={'exclamation-point-icon'} />;
    return <InfoIcon className={'exclamation-point-icon'} />;
  };

  return (
    <button className={'exclamation-wrapper'} onClick={() => onClick()}>
      {tooltipText && (
        <div className={'tooltip'}>
          <p className={'tooltip-text'}>{tooltipText}</p>
        </div>
      )}
      {getIcon(iconType)}
    </button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  iconType: PropTypes.string,
  tooltipText: PropTypes.string
};
