import { useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import Loader from 'components/common/Loader';
import NavigationBar from 'components/common/NavigationBar';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import { filterPaymentAccounts } from 'components/common/PaymentForm/utils';
import { PopUpSessionAutoLogOutScheme } from 'components/common/PopUpScheme/PopUpSessionAutoLogOutScheme';
import { hasMultipleCurrencies } from 'components/utils';
import { useMediaQuery } from 'services/useMediaQUery';
import { isCryptoProvider, MEDIA_QUERY } from 'services/utils';

const ApplicationLayout = ({ userStore }) => {
  const [compactMenu, setCompactMenu] = useState(false);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const isDesktop = useMediaQuery(MEDIA_QUERY.DESKTOP);

  useEffect(() => {
    if (isDesktop === false && !compactMenu && isMobile === false) {
      setCompactMenu(true);
    } else if (isDesktop && compactMenu) {
      setCompactMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isDesktop]);

  const hasCryptoAccounts = () => {
    return userStore.userAccounts.some((account) => isCryptoProvider(account.transfer_provider));
  };

  return (
    <div className={'page application'}>
      {!userStore.isInitialized || userStore.isLoading ? (
        <Loader className={'application-loader'} />
      ) : (
        <>
          <Header collapseMenu={setCompactMenu} compact={compactMenu} />
          <main className={`content ${compactMenu ? 'compact' : isMobile ? 'bg-transparent' : ''}`}>
            <PopUpSessionAutoLogOutScheme />
            <NavigationBar
              isMobile={isMobile}
              collapseMenu={setCompactMenu}
              compact={compactMenu}
              isCustomerExist={!!userStore.userData.account}
              isCustomerVerified={userStore.isCustomerVerified}
              isAccountsExist={userStore.userAccounts.length > 0}
              isCryptoAccountsExist={hasCryptoAccounts()}
              isMultipleCurrency={hasMultipleCurrencies(filterPaymentAccounts(userStore.userAccounts))}
              isFees={userStore.isFeesEnabled()}
            />
            {Object.keys(userStore.userData).length ? <Outlet /> : <NoOutlet error={userStore?.error} />}
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

ApplicationLayout.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject('userStore')(observer(ApplicationLayout));
