import * as Yup from 'yup';
import { LATIN_LETTERS_AND_DIGITS_REG_EXP } from './utils';

export default ({ i18n, onChange, onSubmit, customerNumber, model, previousTransaction }) => ({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    amount: `${previousTransaction?.amount || ''}`,
    recipient_wallet: previousTransaction?.counterparty?.crypto_wallet || '',
    documents: null
  },
  initialStatus: { recipient_wallet: true },
  validationSchema: Yup.object().shape(
    {
      recipient_wallet: Yup.string().when('account_number', {
        then: (schema) =>
          schema
            .trim()
            .required(i18n.getMessage('client.validation.message.isEmpty'))
            .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
              message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
            }),
        is: (account_number) => !account_number,
        otherwise: (schema) => schema.nullable(true)
      }),
      amount: Yup.string().required(i18n.getMessage('client.validation.message.isEmpty'))
    },
    [['account_number', 'recipient_wallet']]
  ),
  onSubmit: (values) => {
    onChange(values);
    onSubmit({ customerNumber, providerData: values, model });
  }
});
