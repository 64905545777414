import PropTypes from 'prop-types';
import TurnstileCaptcha from './components/TurnstileCaptcha';
import GoogleCaptcha from './components/GoogleCaptcha';
import './CustomCaptcha.scss';

const CustomCaptcha = ({ captchaProvider, onChange, siteKey, recaptchaRef, error }) => {
  const handleCaptchaVerify = (value) => {
    onChange(value);
  };
  return (
    <div className={'captcha-wrapper'}>
      {captchaProvider === 'GOOGLE' && (
        <GoogleCaptcha siteKey={siteKey} handleTokenVerify={handleCaptchaVerify} recaptchaRef={recaptchaRef} />
      )}
      {captchaProvider === 'TURNSTILE' && (
        <TurnstileCaptcha siteKey={siteKey} handleTokenVerify={handleCaptchaVerify} />
      )}
      {error && <p className={'captcha-error'}>{error}</p>}
    </div>
  );
};

CustomCaptcha.propTypes = {
  captchaProvider: PropTypes.string,
  onChange: PropTypes.func,
  siteKey: PropTypes.string,
  recaptchaRef: PropTypes.object,
  error: PropTypes.any
};

export default CustomCaptcha;
