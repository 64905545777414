import PropTypes from 'prop-types';

import Input from 'uikit/Input/Input';

const AmountInput = ({
  name,
  error,
  value,
  onChange,
  onBlur = () => {},
  label,
  placeholder,
  subText,
  className,
  isDisabled,
  isRequired,
  precision = 2
}) => {
  const filterInputtedValue = (value) => {
    const [integerPart, ...fractionParts] = value.split('.');

    let filteredValue = integerPart;
    if (!integerPart) {
      return '';
    }
    if (fractionParts.length > 0) {
      filteredValue += '.' + fractionParts.join('').substring(precision, fractionParts.length - precision);
    }

    return filteredValue.replace(/[^0-9.]/gim, '');
  };

  const replaceLastCommaToDot = (value) => {
    if (value.endsWith(',')) {
      return value.substring(0, value.length - 1) + '.';
    }
    return value;
  };

  const handleChange = (e) => {
    let value = e.target.value;

    value = replaceLastCommaToDot(value);
    value = filterInputtedValue(value);

    onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    });
  };
  return (
    <Input
      name={name}
      error={error}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      subText={subText}
      className={className}
      isDisabled={isDisabled}
      isRequired={isRequired}
    />
  );
};

AmountInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  precision: PropTypes.number
};

export default AmountInput;
