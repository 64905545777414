import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import {
  PAYMENT_PROVIDERS,
  WALLESTER_ACCOUNT_TOOLTIP_TEXT,
  ACCOUNT_PROPERTIES,
  ACCOUNT_TOOLTIP_TEXT
} from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import { getAccountTitle } from 'components/utils';
import { useMediaQuery } from 'services/useMediaQUery';
import { isCryptoProvider, MEDIA_QUERY } from 'services/utils';
import paymentStore from 'stores/paymentStore';
import { Card } from 'uikit/Card/Card';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './Accounts.scss';

const Accounts = ({ userAccounts, transactionsStore, cardsStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToTransaction = (account) => {
    transactionsStore.setAccount(account);
    transactionsStore.setIsRedirectFromAccountCard(true);
    navigate('/transactions');
  };

  const goToPayment = (account) => {
    paymentStore.setSelectedAccount(account);
    navigate('/new-payment');
  };

  const goToCards = (account) => {
    cardsStore.setSelectedAccount(account);
    navigate('/cards');
  };

  const checkWallesterProvider = (account) => {
    return account.transfer_provider === PAYMENT_PROVIDERS.WALLESTER;
  };

  const mobileText = {
    account: i18n.getMessage('accounts.iban'),
    currency: i18n.getMessage('accounts.currency'),
    reserved: i18n.getMessage('accounts.reserved'),
    balance: i18n.getMessage('accounts.availableBalance'),
    actions: i18n.getMessage('accounts.actions')
  };

  return (
    <section className='right-section accounts'>
      <Container className='accounts-container' header={i18n.getMessage('container.accounts')}>
        <div className='accounts-list'>
          {!isMobile && (
            <div className='accounts-head'>
              <p className='head-accounts'>{i18n.getMessage('accounts.iban')}</p>
              <p className='head-currency'>{i18n.getMessage('accounts.currency')}</p>
              <p className='head-reserved'>{i18n.getMessage('accounts.reserved')}</p>
              <p className='head-available'>{i18n.getMessage('accounts.availableBalance')}</p>
              <p className='head-actions'>{i18n.getMessage('accounts.actions')}</p>
            </div>
          )}

          {userAccounts?.map((account) => {
            const isWallesterWallet = checkWallesterProvider(account);

            return (
              <Card
                key={account[ACCOUNT_PROPERTIES.ACCOUNT_NUMBER]}
                type={'list'}
                account={getAccountTitle(account)}
                isCryptoAccount={isCryptoProvider(account.transfer_provider)}
                balance={account[ACCOUNT_PROPERTIES.AVAILABLE]}
                currency={account[ACCOUNT_PROPERTIES.CURRENCY]}
                reserved={account[ACCOUNT_PROPERTIES.ON_HOLD]}
                mobileText={mobileText}
                onExclaimPointClick={() => showPopup(account)}
                onPaymentClick={isWallesterWallet ? () => goToCards(account) : () => goToPayment(account)}
                onTransactionClick={() => goToTransaction(account)}
                tooltipText={isWallesterWallet ? WALLESTER_ACCOUNT_TOOLTIP_TEXT : ACCOUNT_TOOLTIP_TEXT}
              />
            );
          })}
        </div>
        <PopUp className='account-info' show={popUpState.open} onClose={closePopUp}>
          <PopUpScheme activeAccount={popUpState.account} onClose={closePopUp} />
        </PopUp>
      </Container>
    </section>
  );
};

Accounts.propTypes = {
  transactionsStore: MobXPropTypes.observableObject,
  cardsStore: MobXPropTypes.observableObject,
  userAccounts: PropTypes.array
};

export default inject((stores) => ({
  userAccounts: stores.userStore?.userAccounts,
  transactionsStore: stores.transactionsStore,
  cardsStore: stores.cardsStore
}))(observer(Accounts));
