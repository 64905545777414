import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';

import authStore from 'stores/authStore';
import registrationStore from 'stores/registrationStore';
import userStore from 'stores/userStore';
import passwordRecoveryStore from 'stores/passwordRecoveryStore';
import paymentStore from 'stores/paymentStore';
import transactionsStore from 'stores/transactionsStore';
import betweenAccountsStore from 'stores/betweenAccountsStore';
import currencyExchangeStore from 'stores/currencyExchangeStore';
import messagesStore from 'stores/messagesStore';
import activityLogStore from 'stores/activityLogStore';
import representativeStore from 'stores/representativeStore';
import cardsStore from 'stores/cardsStore';
import paymentObligationsStore from 'stores/paymentObligationsStore';
import App from 'components/App';

import 'assets/fonts/Manrope-Regular.ttf';
import 'assets/fonts/Manrope-SemiBold.ttf';
import 'bootstrap/dist/css/bootstrap.css';
import 'styles.scss';

const stores = {
  authStore,
  registrationStore,
  userStore,
  passwordRecoveryStore,
  paymentStore,
  transactionsStore,
  betweenAccountsStore,
  currencyExchangeStore,
  messagesStore,
  activityLogStore,
  representativeStore,
  cardsStore,
  paymentObligationsStore
};

const root = createRoot(document.getElementById('root'));
root.render(
  // <StrictMode>
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </StrictMode>
);
