import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const GoogleCaptcha = ({ handleTokenVerify, siteKey, recaptchaRef }) => {
  return <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={handleTokenVerify} />;
};

GoogleCaptcha.propTypes = {
  handleTokenVerify: PropTypes.func,
  siteKey: PropTypes.string,
  recaptchaRef: PropTypes.object
};

export default GoogleCaptcha;
