import PropTypes from 'prop-types';
import { ReactComponent as CardsAccountIcon } from 'assets/currencies/cards-account-icon.svg';
import './CurrencyIcon.scss';

export const CurrencyIcon = ({ isCardAccount = false, currencyIcon: CurrencyComponent }) => {
  return (
    <div className={'currency-icon-wrapper'}>
      {isCardAccount ? <CardsAccountIcon /> : CurrencyComponent && <CurrencyComponent />}
    </div>
  );
};

CurrencyIcon.propTypes = {
  isCardAccount: PropTypes.bool,
  currencyIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.oneOf([null])])
};
